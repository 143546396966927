<template>
    <div class="moreCategories">

        <Head :centerInfo="true"></Head>
        <div class="moreCategoriesBanner">
            <img :src="require('../assets/more'+navType+'.png')" alt="">
            <div class="mauto">
                <img :src="require('../assets/moreIcon'+navType+'.png')" alt="">
            </div>
        </div>
        <div class="mauto moreCategoriesContent">
            <!-- <ul>
                <li v-for="(item,index) in listData"
                    @click="jump(item)" :key="index">
                    <img :src="item.coverUrl" alt="">
                    <span>{{handleTime(item.duration)}}</span>
                </li>
            </ul> -->
             <div class="search_box">
                <div class="searchBox_info" v-for="(item,index) in listData" :key="index">
                    <img @click="jump(item)"
                        :src="item.coverUrl" alt="">
                    <div class="videoDetail">
                        <p class="playNum">
                            <span class="iconfont icon-bofang"></span>
                            {{item.playNum}}
                        </p>
                        <p class="fabulousNum" @click="goodVFn(item,index)" :class="item.isMyGood == 1?'checkedzan':''">
                            <span class="iconfont icon-dianzan1"></span>
                            {{item.goodNum}}
                        </p>
                        <p class="videoTime">{{parseInt(parseInt(item.duration)/60)}}:{{parseInt(item.duration)%60}}</p>
                    </div>

                    <p class="searchVideo_title">{{item.videoTitle}}</p>
                    <p class="searchVideo_time">{{item.approveDate}}</p>
                </div>
            </div>
            <pagination v-show="total>0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.size"
                @pagination="getList" />
            <!--无数据-->
            <el-empty v-if="listData.length == 0" image="http://image.ourgame.com/videoWeb/null.png" description=" ">
            </el-empty>
        </div>
    </div>
</template>

<script>
    import Head from '@/components/head.vue';
    import pagination from '@/components/pagination.vue'
    import { getNewList,getPopularList,cancelGoodVideo,goodVideo } from '@/api/authority'
    export default {
        name: "moreCategories",
        props: {
            navType:{
                type:String,
                default:1
            }
        },
        components: {
            Head,
            pagination
        },
        data() {
            return {
                type: 1,
                listData: [],
                total: 0,
                listQuery: {
                    current: 1,
                    size: 20,
                }
            }
        },
        mounted() {
            console.log(this.navType)
            this.getList()
        },
        methods: {
            jump(item){
                this.$store.commit('JUMP_PLAY',{alid:item.aliVideoId,videoId:item.videoID,vue:this})
            },
            getList(parm) {
                if (typeof parm != 'undefined') {
                    this.listQuery.current = parm.page
                }
                // type 1最新 2最热    
                if (this.navType == 1) {
                    this.getNewListFn()
                } else if (this.navType == 2) {
                    this.getPopularListFn()
                }
            },
               //点赞   
            goodVFn(item, index) {
                if (item.isMyGood == 0) {
                    this.goodVideoFn(item.videoID, index)
                } else {
                    this.cancelGoodVideoFn(item.videoID, index)
                }
            },
                   //取消点赞
            cancelGoodVideoFn(id, index) {
                cancelGoodVideo({
                    videoID: id
                }).then(response => {
                    if (response.data.code == 0) {
                        this.listData[index].goodNum--
                        this.listData[index].isMyGood = 0
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            //点赞
            goodVideoFn(id, index) {
                goodVideo({
                    videoID: id
                }).then(response => {
                    if (response.data.code == 0) {
                        this.listData[index].goodNum++
                        this.listData[index].isMyGood = 1
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            //最新
            getNewListFn() {
                getNewList(this.listQuery).then(response => {
                    if (response.data.code == 0) {
                        this.listData = response.data.data.videoDTOPage.records
                        this.total = response.data.data.videoDTOPage.total
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            //最热    
            getPopularListFn() {
                getPopularList(this.listQuery).then(response => {
                    if (response.data.code == 0) {
                        this.listData = response.data.data.videoDTOPage.records
                        this.total = response.data.data.videoDTOPage.total
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            }
        }


    }
</script>

<style lang="less">
    .moreCategories {
        // .search_box{
        //     margin:0px;
        // }
          .search_box {
                margin: 0px;
                width: 1249px;
                box-sizing: border-box;

                //    padding-left:246px;
                .searchBox_info {
                    margin: 16px 0px 0px 24px;
                    //    border:9px solid black;
                    overflow: hidden;
                    position: relative;
                    color: white;
                    background: white;
                    width: 224px;
                    float: left;

                    img {
                        width: 224px;
                        height: 178px;
                        cursor: pointer;
                    }

                    .videoDetail {
                        position: absolute;
                        font-size: 12px;
                        top: 158px;
                        width: 224px;
                        left: 0px;
                        cursor: pointer;

                        p {
                            color: white;
                            float: left;
                            padding-left: 9px;

                            &:hover {
                                color: #ff8a00
                            }
                        }

                        .fabulousNum {
                            padding-left: 14px;
                        }

                        .videoTime {
                            float: right;
                            padding-right: 9px;

                            &:hover {
                                color: white;
                            }
                        }
                    }

                    .searchVideo_title {
                        font-size: 14px;
                        color: #333333;
                        padding: 15px 0px 0px 9px;
                        width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .searchVideo_time {
                        padding: 15px 0px 8px 9px;
                        color: #b8b8b8;
                        font-size: 12px;
                    }
                }
            }
        .moreCategoriesBanner {
            padding-top: 65px;
            height: 222px;
            position: relative;
            >img{
                width:100%;
                height:222px;
                position: absolute;
                // top:0px;
                left:0px;
            }
            >div {
                position: relative;
                top: 0px;
                left: 0px;
                height: 222px;

                img {
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                }
            }

            .matuo {
                img {
                    margin: 154px 0px 0px 10px;
                }
            }
        }

        .moreCategoriesContent {
            padding-top: 32px;

            ul {
                margin: -20px 0px 0px -20px;

                li {
                    margin: 20px 0px 0px 20px;
                    border-radius: 8px;
                    overflow: hidden;
                    float: left;
                    position: relative;
                    cursor: pointer;

                    img {
                        width: 224px;
                        height: 176px;
                    }

                    span {
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                        color: white;
                    }
                }
            }
        }
    }
</style>